import Translations from "../../../translations";
import Control from "../control";

const RecognitionModal = ({showModal, setShowModal}) => {
    return (
        <div className={["modal", showModal == 'recognition' ? 'show' : 'hide'].join(' ')}>
            <div className={["content-modal", "reconhecimento-background", "reconhecimentomodal"].join(' ')}>
            
                <header>
                    <div className="container">
                        <h2><Translations>modal.recognition.name</Translations></h2>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="row w-100 base-side">
                            <div className="left-side">
                                <div className="d-flex jusfy-content-center align-items-center mb-5 insignia-1">
                                    <figure><img src={require('../../../assets/img/reconhecimento/selo-2021.png')} /></figure>
                                    <div className="p-2">
                                        <h3><Translations>modal.recognition.analysis</Translations></h3>
                                        <p><Translations>modal.recognition.we_have</Translations></p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <ul>
                                        <Translations>modal.recognition.list_1</Translations>
                                    </ul>
                                    <ul>
                                    <Translations>modal.recognition.list_2</Translations>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="d-flex align-items-center mb-5 insignia-1">
                                    <figure><img src={require('../../../assets/img/reconhecimento/selo-2022.png')} /></figure>
                                    <div className="p-2">
                                        <Translations>modal.recognition.list_3</Translations>
                                    </div>
                                </div>
                                <div className="d-flex colum chambers">
                                    <figure><img src={require('../../../assets/img/reconhecimento/chambers.png')} /></figure>
                                    <br />
                                    <br />
                                    <p><Translations>modal.recognition.operation_america</Translations></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Control showModal={showModal} next={() => {
                    setShowModal('actings')
                }} close={() => {
                    setShowModal('')
                }} before={() => {
                    setShowModal('')
                }} />
            </div>
        </div>
    )
}

export default RecognitionModal