import Api from "../../../api/api"
import i18n from '../../../translations/i18n'

class ProfesionalsController {
    GetAll = async (callback) => {

        callback.init()
        const response = await Api.GET(`/professionals/language/${Api.GetLanguage()}`)

        if(response.status === true){
            return callback.success(response)
        }

        return callback.error(response)
    }

    GetImage = (id, width = 0) => {
        return Api.handleURL(`/images/professionals/${id}.png`)
    }
    
}

export default ProfesionalsController