window.$(window).on("load", function () {
  
  // function scrollheader() {
  //   const nav = document.getElementById("header");
  //   if (this.scrollY >= 150) nav.classList.add("active-header");
  //   else nav.classList.remove("active-header");
  // }
  // window.addEventListener("scroll", scrollheader);


  //Scroll Custom
  setTimeout(() => {
    window.$("#news-mCustomScrollbar").mCustomScrollbar({ theme:"rounded" });
    window.$("#articles-mCustomScrollbar").mCustomScrollbar({ theme:"rounded" });
  }, 3000)



  //Preloader
  setTimeout(function () {
    window.$(document).ready(function () {
      window.$("#preloader").addClass("removepreloader");
    });
  }, 1500);


  // MENU MOBILE
  setTimeout(() => {
    const toggle = window.$('#bx');
    const nav = window.$('#menu-mobile');


    if (toggle && nav) {
      toggle.click(function(){
        nav.toggleClass("active-menu-mobile");
        toggle.toggleClass("active-bx");
      });
    }

    window.$('.nav-link-desktop').click(function(){
      window.$('.active-bx').click();
    })
  }, 1000)



  
});
