export const TRANSLATIONS_PT = {
    home : {
        Excellence: `PLANEJAMENTO, NEGOCIAÇÃO E IMPLEMENTAÇÃO JURÍDICA DE PROJETOS COMPLEXOS`,
        We_are_an_office: `<p>Somos um Escritório especializado no planejamento, negociação e implementação jurídica de negócios complexos e inovadores, de caráter empresarial ou público-privado, no Brasil e internacionalmente. A combinação da excelência na formação jurídica, vivência empresarial e habilidade de negociação permitem atuação diferenciada e concepção de alternativas estratégicas.</p>
        <p>Nossos clientes atuam em diversos mercados, incluindo os altamente regulados, como os setores de Infraestrutura e Tecnologia, Energia, Óleo e Gás, Transportes e Logística, Água e Gestão de Resíduos, Tecnologia da Informação, Comunicações, além dos setores Previdenciário Complementar, Financeiro e Securitário. Nos mercados não regulados, o Escritório atua intensivamente nos setores da Construção Civil, Industrial, Imobiliário e Agronegócio. Em todos os setores, o escritório atende empresas em diferentes estágios de desenvolvimento, tanto empresas consolidadas como startups e empresas em crescimento ou em processo de reestruturação.</p>
        <p>Em razão do caráter não repetitivo dos trabalhos realizados, e dos requisitos de credibilidade e reputação que exigem, o Escritório também se tornou reconhecido como referência no tratamento de situações de crise empresarial, incluindo as que demandam a busca de soluções consensuais com autoridades governamentais ou entre sócios, a adaptação de estruturas de governança corporativa, implementação de mecanismos de integridade e proposta de mudanças regulatórias em diversos setores.</p>
        <p>Nossos sócios fundadores e sêniores possuem sólida formação acadêmica e experiência executiva, como ex-diretores jurídicos e advogados de empresas, dedicando-se de maneira direta e ativa aos casos sob nossos cuidados, buscando combinar uma compreensão aprofundada de propósitos negociais e necessidades empresariais, com análise rigorosa de questões técnico-jurídicas.</p>`,
        We_are_an_office_mobile: ` <p>Somos um Escritório especializado no planejamento, negociação e implementação jurídica de negócios complexos e inovadores, de caráter empresarial ou público-privado, no Brasil e internacionalmente. </p>
        <p>Nossos clientes atuam em diversos mercados, como os setores de Infraestrutura e Tecnologia, Energia, Óleo e Gás, Transportes e Logística, Água e Gestão de Resíduos, Tecnologia da Informação, Comunicações, além dos setores Previdenciário Complementar, Financeiro e Securitário, Construção Civil, Industrial, Imobiliário e Agronegócio. </p>
        <p>Em razão  e de sua credibilidade e reputação, 
o Escritório também é referência no tratamento de situações de crise empresarial, que demandam 
a busca de soluções consensuais com autoridades governamentais ou entre sócios, a adaptação de estruturas de governança corporativa e implementação de mecanismos de integridade.</p>`,
        Complex_contracts: `<p><a href="">Contratos Complexos</a></p>
        <p><a href="">Societário, M&A e Private Equity  </a></p>
        <p><a href="">Acordos de colaboração empresarial, apoio à gestão de crises e efetividade de programas de integridade (compliance) anticorrupção </a></p>
        <p><a href="">Administrativo e Regulatório</a></p>
        <p><a href="">Startups e Fintechs </a></p>`,
        Conflict_resolution: `<p><a href="">Solução de conflitos (contencioso e arbitragem)</a></p>
        <p><a href="">Previdência complementar </a></p>
        <p><a href="">Mercado Imobiliário</a></p>
        <p><a href="">Tributário</a></p>`,
        Areas_of_expertise: "Áreas de atuação"
    },
    footer: {
        Contact: "Contato",
        Name: "Barros Pimentel<br /> Alcantara Gil<br /> Rodriguez <br />Advogados",
        Address: `<p>AV. Pres. Juscelino Kubitschek, 360 - conj. 61<br></br> 04543-000 São Paulo, SP - Brasil </p>
        <p className="noline"><a target='_blank' href="https://www.google.com/maps/place/Av.+Pres.+Juscelino+Kubitschek,+360+-+61+-+Vila+Nova+Concei%C3%A7%C3%A3o,+S%C3%A3o+Paulo+-+SP,+13571-410/@-23.5870333,-46.6809352,17z/data=!4m5!3m4!1s0x94ce574430617541:0x39e8d53d1da721ae!8m2!3d-23.5860987!4d-46.6736082?entry=ttu" className="mapsgoogle">Ver no google maps</a></p>
        <p  className="noline"><a  target='_blank' href="https://www.waze.com/pt-BR/live-map/directions/br/sp/av.-pres.-juscelino-kubitschek,-360?navigate=yes&to=place.ChIJVVK2MVlXzpQRBDWqND6Hoh4" className="mapswaze">Ir para o Waze</a></p>`,
        Phone: "+55 11 3896.1600",
        Email: "barrospimentel@barrospimentel.adv.br",
        Linkedin: "Barros Pimentel Advogados",
        Copy: `<li><p><span className="c-primary">Barros Pimentel</span> Alcantara Gil <span className="c-primary">Rodriguez</span> Advogados</p></li>
        <li>
            <a href="#home" className="voltartopo text-uppercase c-primary" title="Voltar ao topo">Início</a>
        </li>
        <li><p className="c-primary">#BP2022</p></li>`
    },

    menu: {
        Home: `Home`,
        Who_we_are: `Quem Somos`,
        Recognition: `Reconhecimento`,
        Performance: `Atuação`,
        Professionals: `Profissionais`,
        News: `Mídia`,
        Contact: `Contato`,
    },

    modal: {
        read_the_article: 'ler o artigo',
        read_news: 'ler a notícia',
        recognition : {
            name: "Reconhecimento",
            analysis: "Análise Advocacia",
            we_have: "Estamos entre os escritórios e advogados mais admirados do Brasil desde 2010",
            list_1: `<h4>Especialidades</h4>
            <li>Cível</li>
            <li>Compliance</li>
            <li>Contratos Empresariais</li>
            <li>Infraestrutura e Regulatório</li>
            <li>Operações Financeiras</li>
            <li>Societário</li>`,
            list_2: `<h4>Setor econômico</h4>
            <li>Açúcar e álcool</li>
            <li>Construção e Engenharia</li>
            <li>Energia Elétrica</li>
            <li>Imobiliário</li>
            <li>Tecnologia</li>
            <li>Transporte e Logística</li>`,
            list_3: `<h3>Análise Advocacia</h3>
            <h4>Diversidade e inclusão</h4>
            <p>Escritórios que fazem a diferença</p>`,
            operation_america: 'Atuação na América Latina reconhecida pelo Chambers,desde 2012, na área de Projetos'
    
        },
        control: {
            previous: "anterior",
            home: "home",
            next: "próximo",
            Home: `Home`,

            Who_we_are: `quem somos`,
            Recognition: `reconhecimento`,
            Performance: `atuação`,
            Professionals: `profissionais`,
            News: `mídia`,
            Contact: `contato`,
            articles: `artigos`
        },
        acting: {
            title:`Atuação`,
        },
        articles: {
            title:`Artigos`,
        },
        news: {
            title:`Notícias`,
        }
    }
};