
import React, {useState} from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Header from "./view/header";
import Footer from "./view/footer";

import Index from "./view/pages/home";
import './app.scss'
import { withTranslation } from 'react-i18next';

import './assets/js/site.js'


function App() {
  const [showModal, setShowModalOverride] = useState('');
  const [linkActive, setLinkActive] = useState('home');

  const setShowModal = (modal, scrollTo) => {
    window.history.replaceState("", "", window.location.pathname)

    if(scrollTo != undefined) {
      setTimeout(() => {
        window.location.href = scrollTo
      }, 300)
    }
    
    if(modal != ''){
      document.body.style.overflow = "hidden"
    }else{
      document.body.style.overflow = "auto"
    }
    setShowModalOverride(modal)
    alterLinkActive(modal)
  }

  const alterLinkActive = (modal) => {
    switch (modal){
      case 'recognition':
        setLinkActive('reconhecimento')
        break
      case 'actings':
        setLinkActive('atuacao')
        break
      case 'profissional':
        setLinkActive('profissionais')
        break
      case 'news':
        setLinkActive('midias')
        break
      case 'articles':
        setLinkActive('midias')
        break
      case 'who_we_are':
        setLinkActive('contato')
        break
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header showModal={showModal} setShowModal={setShowModal} linkActive={linkActive} setLinkActive={setLinkActive} />  
          <Routes>
            <Route path="*" element={<Index showModal={showModal} setShowModal={setShowModal} />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default withTranslation()(App);


