import React, {useEffect, useState} from 'react';
import NewsController from './news.controller';
import Translations from '../../translations';

const News = ({news, setNews, showModal, setShowModal}) => {
    const newsController = new NewsController()

    useEffect(() => {
        newsController.GetAll({
            init: () => {
                
            },
            success: (response) => {
                setNews(response.object)
            },
            error: (response) => {
                console.error(response)
            }
        });
    }, [])
    
    return (
        <>
            <div className="boxnoticias" id="news-mCustomScrollbar" data-mcs-theme="rounded">
                {news.length > 0 ? (
                    news.map((item, index) => {
                        return <a onClick={() => {setShowModal('news', `#new-${index}`)}} key={index}>{item.name}</a>
                    })
                ) : '' }
            </div>
        </>
    )
}

export default News;