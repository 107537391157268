import Translations from "../../translations";
import { ReactComponent as HomeSVG } from '../../assets/svg/home.svg';

const Control = ({close, next, before, showModal}) => {

    const getTranslationBefore = () => {
        if(showModal == 'recognition'){
            return <Translations>modal.control.Who_we_are</Translations>
        }

        if(showModal == 'actings'){
            return <Translations>modal.control.Recognition</Translations>
        }

        if(showModal == 'profissional'){
            return <Translations>modal.control.previous</Translations>
        }

        if(showModal == 'news'){
            return <Translations>modal.control.Professionals</Translations>
        }

        if(showModal == 'articles'){
            return <Translations>modal.control.News</Translations>
        }
        
        return <Translations>modal.control.Who_we_are</Translations>
    }

    const getTranslationNext = () => {
        if(showModal == 'recognition'){
            return <Translations>modal.control.Performance</Translations>
        }

        if(showModal == 'actings'){
            return <Translations>modal.control.Professionals</Translations>
        }

        if(showModal == 'profissional'){
            return <Translations>modal.control.next</Translations>
        }

        if(showModal == 'news'){
            return <Translations>modal.control.articles</Translations>
        }

        if(showModal == 'articles'){
            return <Translations>modal.control.Who_we_are</Translations>
        }



        return <Translations>modal.control.Who_we_are</Translations>
    }

    const getTranslationHome = () => {
        return <Translations>modal.control.home</Translations>
    }

    return (
        <footer>
            <div className="container">
                <div className="controles">
                    <button className="prevbr" onClick={() => { before() }}>
                        {getTranslationBefore()}
                        <img src={require("../../assets/img/PrevSVG.png")} />
                    </button>
                    <button className="hombt" onClick={() => {close()}}>
                        <div className="homeControler"></div>
                        {getTranslationHome()}
                    </button>
                    <button className="nextbt" onClick={() => { next() }}>
                        <img src={require("../../assets/img/NextSVG.png")} />
                        {getTranslationNext()}
                    </button>
                </div>
            </div>
        </footer>
    )
}

export default Control  