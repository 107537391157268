import React, {useEffect, useState} from 'react';
import Translations from '../../translations';
import ArticlesController from './articles.controller';

const Articles = ({articles, setArticles, showModal, setShowModal}) => {
    const articlesController = new ArticlesController()

    useEffect(() => {
        articlesController.GetAll({ 
            init: () => {},
            success: (response) => {
                setArticles(response.object)
            },
            error: (response) => {
                console.log('error', response)
            }
        });
    }, [])

    return(
        <>
            <div className="boxartigos" id="articles-mCustomScrollbar">
                {articles.length > 0 ? (
                    articles.map((item, index) => {
                        return <a onClick={() => { setShowModal('articles', `#articles-${index}`) }} key={index}>{item.name}</a>
                    })) : '' 
                }
            </div>
        </>
    )
}

export default Articles;