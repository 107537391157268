import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css/navigation";

import ProfesionalsController from "../modal/profissional/professional.controller";
import { useNavigate, useParams, Link } from 'react-router-dom';

const ProfessionalsSwiper = ({ 
  showModal, 
  setShowModal, 
  searchProfesional, 
  setProfessionals, 
  profesional, 
  profesionals, 
  setProfessional, 
  indexProfesional, 
  setIndexProfesional
}) => {
  const swiperRef = useRef(null)
  const profesionalsController = new ProfesionalsController();
  const [searchProfesionalInner, setSearchProfesionalInner] = useState(searchProfesional)
  const [current, setCurrent] = useState(0)
  let params = useParams();
 
  useEffect(() => {
    swiperRef.current.swiper.slideTo(0);
    profesionalsController.GetAll({
      init: () => {},
      success: (data) => {
        setProfessionals(data.object);
      },
      error: (data) => {
        console.log(data);
      },
    });
  }, []);

  useEffect(() => {
    if(searchProfesional.includes(" ")) {
      searchProfesional = searchProfesional.split(' ').pop()
    }

    setSearchProfesionalInner(searchProfesional)
  }, [searchProfesional])

  useEffect(() => {
    var name = params["*"]
    if(name == '') return

    const profIndex = profesionals.findIndex((p) => {
      return p.name.toLowerCase() == name.toLowerCase()
    })

    if(profIndex == -1) return;

    setShowModal('profissional')
    setProfessional(profesionals[profIndex]);
    setIndexProfesional(profIndex);
  }, [profesionals])


  return (
    <>
    <div className="btsslide">
    {profesionals.filter((prof) => prof.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchProfesionalInner.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).length > 8 ? (
          <button className='swiper-button-prev' onClick={() => {
            const setLocalCurrent = 0
            setCurrent(setLocalCurrent)
            swiperRef.current.swiper.slideTo(setLocalCurrent);
          }}/>
        ) : ('') }
        <span>deslize para os lados</span>
        {profesionals.filter((prof) => prof.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchProfesionalInner.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).length > 8 ? (
          <button className='swiper-button-next' onClick={() => {
            const setLocalCurrent = current+8
            setCurrent(setLocalCurrent)
            swiperRef.current.swiper.slideTo(setLocalCurrent);
          }}/>
        ) : ('') }
        </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={8}
        pagination={{
          clickable: true,
        }}
        ref={swiperRef}
        breakpoints={{
          // when window width is >= 640px
          0: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          991: {
            slidesPerView: 8,
            spaceBetween: 20
          },
        }}
      >
        {profesionals.filter((prof) => prof.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchProfesionalInner.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).map((profesional, index) => { 
          return (
            <SwiperSlide key={index} className={`item-swipper-${index}`}>
              <a data-title={profesional.name} onClick={() => {
                  setShowModal('profissional', `#profissional-${index}`)
                  setProfessional(profesional);
                  setIndexProfesional(profesionals.findIndex((p) => {
                    return p.id == profesional.id
                  }));
                }}>
                <figure>
                  <img src={profesionalsController.GetImage(profesional.id, 200)} className={`class-id-${profesional.id}-name-${profesional.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(' ', '-')}`} />
                </figure>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default ProfessionalsSwiper;