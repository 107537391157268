import Api from "../../api/api"

class BannersController {
    GetAll = async (callback) => {
        callback.init()
        const response = await Api.GET('/banners/index.php')

        if(response.status === true){
            return callback.success(response)
        }
        return callback.error(response)
    }

    GetImage = (id) => {
        return Api.handleURL(`/images/banners/${id}.png`)
    }
}

export default BannersController