import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper"
import BannersController from './banners.controller'

const Banner = () => {
    const bannersController = new BannersController()
    const [banners, setBanners] = React.useState([])
    const [show, setShow] = React.useState(true)

    React.useEffect(() => {
        bannersController.GetAll({
            init: () => {},
            success: (data) => {
                setBanners(data.object);
            },
            error: (data) => {
                console.log(data);
            },
        });
    }, []);


    return (
        <>
            <Swiper 
              modules={[Pagination, Autoplay]} 
              slidesPerView={1} 
              pagination={{ clickable: true }} 
              className="mybanner"
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
            {banners.map((banner, index) => {
                return(
                    <SwiperSlide key={index}>
                        <figure>
                            { show ? <div className={"lds-ripple"}><div></div><div></div></div> : ''}
                            <img src={bannersController.GetImage(banner.id)} onLoad={() => {setShow(false)}} />
                        </figure>
                    </SwiperSlide>
                )
            })}
          </Swiper>
        </>
    )
}

export default Banner