import Translations  from '../../translations';
import i18n from '../../translations/i18n';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as LogoSvg} from '../../assets/svg/iconelogo.svg'
import { ReactComponent as LogoIcon} from '../../assets/svg/logo-icon.svg'
import React, {useState } from "react";

const Header = ({showModal, setShowModal, linkActive,  setLinkActive}) => {
    
    const navigate = useNavigate()

    const updateLink = (link) => {
        setLinkActive(link)
    }

    const updateModal = (link) => {
        setShowModal(link)
    }
    
    return (

        //Header
        <header id="header" style={{opacity: showModal == '' ? .9 : 1}}>
            <nav className="navbar-mobile">
                <div className="logo">
                    <a href="#home"><img src={require('../../assets/mobile/logo-mobile.png')} /></a>
                </div>
                <div className="bx" id="bx">
                    <span className='bx-bar bx-bar-0'></span>
                    <span className='bx-bar bx-bar-1'></span>
                    <span className='bx-bar bx-bar-2'></span>
                    <span className='bx-bar bx-bar-4'></span>
                </div>
            </nav>
            <nav className="navbar-desktop">
                <div className="navbar-desktop-item">
                    
                    <div className="logo">
                        <a href="#home"><img src={require('../../assets/img/logobranca.png')} /></a>
                        
                    </div>
                    <ul className="menu-desktop">
                        <li className="menu-item">
                            <a href="#home" onClick={() => { 
                                updateModal('')
                                updateLink('home')
                            }} className={["nav-link-desktop", linkActive == 'home' ? "active" : ''].join(' ')}><Translations>menu.Home</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#quemsomos" onClick={() => {
                                updateModal('')
                                updateLink('quemsomos')
                            }} className={["nav-link-desktop", linkActive == 'quemsomos' ? "active" : ''].join(' ')}><Translations>menu.Who_we_are</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#atuacao" onClick={() => {
                                updateModal('')
                                updateLink('atuacao')
                            }} className={["nav-link-desktop", linkActive == 'atuacao' ? "active" : ''].join(' ')}><Translations>menu.Performance</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#profissionais" onClick={() => {
                                updateModal('')
                                 updateLink('profissionais')
                             }} className={["nav-link-desktop", linkActive == 'profissionais' ? "active" : ''].join(' ')}><Translations>menu.Professionals</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#midias" onClick={() => {
                                updateModal('')
                                updateLink('midias')
                            }} className={["nav-link-desktop", linkActive == 'midias' ? "active" : ''].join(' ')}><Translations>menu.News</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#reconhecimento" onClick={() => {
                                updateLink('reconhecimento')
                                updateModal('recognition')
                            }} className={["nav-link-desktop", linkActive == 'reconhecimento' ? "active" : ''].join(' ')}><Translations>menu.Recognition</Translations></a>
                        </li>
                        <li className="menu-item">
                            <a href="#contato" onClick={() => {
                                updateModal('')
                                updateLink('contato')
                            }} className={["nav-link-desktop", linkActive == 'contato' ? "active" : ''].join(' ')}><Translations>menu.Contact</Translations></a>
                        </li>
                    </ul>
                    <ul className="menu-desktop">
                        <li className="menu-item">
                            <button onClick={() => {
                                i18n.changeLanguage("pt")
                                window.location.reload()
                            }} className={['nav-link-desktop', i18n.language == 'pt' ? 'active' : ''].join(' ')}>Por</button>
                        </li>
                        <li className="menu-item">
                            <button onClick={() => {
                              i18n.changeLanguage("en")
                              window.location.reload()
                            }} className={['nav-link-desktop', i18n.language == 'en' ? 'active' : ''].join(' ')}>Eng</button>
                        </li>
                    </ul>
                    <ul className="menu-desktop">
                        <li>
                        <a href="https://www.linkedin.com/company/1407667/admin/" target={`_blank`}><img src={require('../../assets/img/linkedin.png')}  /></a>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav className="menu-mobile" id="menu-mobile" >
                <ul className="nav-mobile">
                    <li className="menu-item">
                        <a href="#home" onClick={() => {
                            updateModal('')
                            updateLink('home')
                        }} className={["nav-link-desktop", linkActive == 'home' ? "active" : ''].join(' ')}><Translations>menu.Home</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#quemsomos" onClick={() => {
                            updateModal('')
                            updateLink('quemsomos')
                        }} className={["nav-link-desktop", linkActive == 'quemsomos' ? "active" : ''].join(' ')}><Translations>menu.Who_we_are</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#reconhecimento" onClick={() => {
                            updateModal('')
                            updateLink('reconhecimento')
                            updateModal('recognition')
                        }} className={["nav-link-desktop", linkActive == 'reconhecimento' ? "active" : ''].join(' ')}><Translations>menu.Recognition</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#atuacao" onClick={() => {
                            updateModal('')
                            updateLink('atuacao')
                        }} className={["nav-link-desktop", linkActive == 'atuacao' ? "active" : ''].join(' ')}><Translations>menu.Performance</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#profissionais" onClick={() => {
                            updateModal('')
                            updateLink('profissionais')
                        }} className={["nav-link-desktop", linkActive == 'profissionais' ? "active" : ''].join(' ')}><Translations>menu.Professionals</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#midias" onClick={() => {
                            updateModal('')
                            updateLink('midias')
                        }} className={["nav-link-desktop", linkActive == 'midias' ? "active" : ''].join(' ')}><Translations>menu.News</Translations></a>
                    </li>
                    <li className="menu-item">
                        <a href="#contato" onClick={() => {
                            updateModal('')
                            updateLink('contato')
                        }} className={["nav-link-desktop", linkActive == 'contato' ? "active" : ''].join(' ')}><Translations>menu.Contact</Translations></a>
                    </li>
                </ul>
                <ul className="nav-mobile">
                    <li className="menu-item">
                        <button onClick={() => {
                            i18n.changeLanguage("pt")
                            window.location.reload()
                        }} className={['nav-link-desktop', i18n.language == 'pt' ? 'active' : ''].join(' ')}>Por</button>
                    </li>
                    <li className="menu-item">
                        <button onClick={() => {
                            i18n.changeLanguage("en")
                            window.location.reload()
                        }} className={['nav-link-desktop', i18n.language == 'en' ? 'active' : ''].join(' ')}>Eng</button>
                    </li>
                </ul>
                <ul className="nav-mobile">
                    <li>
                        <a href="https://www.linkedin.com/company/1407667/admin/" target={`_blank`}><img src={require('../../assets/img/linkedin.png')}  /></a>
                    </li>
                </ul>
            </nav>
        </header>

        
    )
}

export default Header