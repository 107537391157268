export const TRANSLATIONS_EN = {
    home : {
        Excellence: `Legal planning, negotiation and implementation of complex projects`,
        We_are_an_office: `<p>Our firm specializes in planning, negotiating and implementing complex and innovative deals, primarily of corporate and public-private nature, both in Brazil and abroad. Our combination of sophisticated legal education, corporate expertise and negotiation skills is what makes us a unique practice and enables us to conceive strategic solutions to our clients.</p>
        <p>Our clients come from diverse industries, especially highly regulated ones, such as Infrastructure & Technology, Energy, Oil & Gas, Transport & Logistics, Water & Waste Management, Information Technology, Communications, and also Supplementary Pension, Financial, and Insurance industries. Our firm has a strong presence in non-regulated markets, including in the Construction, Industrial, Real Estate, and Agribusiness industries. In all these sectors, our firm serves businesses in distinct stages, from startups and growing businesses to mature businesses, as well as those undergoing reorganization processes.</p>
        <p>We are often asked to perform sophisticated, non-repetitive legal services that require trustworthiness and enviable reputation. That is why our firm has provided the benchmark for handling corporate crises, including those involving consensual solutions with government authorities or between shareholders, changes to corporate governance structures, implementation of integrity mechanisms and suggestions of regulatory changes in an array of industries.</p>
        <p>We build on our founding and senior partners’ sound academic education and previous executive experience either as officers or in-house counsels. They are fully committed to dealing with cases in a straightforward and active manner, combining deep knowledge of the purposes behind negotiations and corporate needs and conducting thorough in-depth analysis of technical and legal issues.</p>`,
        We_are_an_office_mobile: `<p>Our firm specializes in planning, negotiating and implementing complex and innovative deals, primarily of corporate and public-private nature, both in Brazil and abroad. Our combination of sophisticated legal education, corporate expertise and negotiation skills is what makes us a unique practice and enables us to conceive strategic solutions to our clients.</p>
        <p>Our clients come from diverse industries, especially highly regulated ones, such as Infrastructure & Technology, Energy, Oil & Gas, Transport & Logistics, Water & Waste Management, Information Technology, Communications, and also Supplementary Pension, Financial, and Insurance industries. Our firm has a strong presence in non-regulated markets, including in the Construction, Industrial, Real Estate, and Agribusiness industries. In all these sectors, our firm serves businesses in distinct stages, from startups and growing businesses to mature businesses, as well as those undergoing reorganization processes.</p>
        <p>We are often asked to perform sophisticated, non-repetitive legal services that require trustworthiness and enviable reputation. That is why our firm has provided the benchmark for handling corporate crises, including those involving consensual solutions with government authorities or between shareholders, changes to corporate governance structures, implementation of integrity mechanisms and suggestions of regulatory changes in an array of industries.</p>
        <p>We build on our founding and senior partners’ sound academic education and previous executive experience either as officers or in-house counsels. They are fully committed to dealing with cases in a straightforward and active manner, combining deep knowledge of the purposes behind negotiations and corporate needs and conducting thorough in-depth analysis of technical and legal issues.</p>`,
        Complex_contracts: `<p><a href="">Contratos Complexos</a></p>
        <p><a href="">Societário, M&A e Private Equity  </a></p>
        <p><a href="">Acordos de colaboração empresarial, apoio à gestão de crises e efetividade de programas de integridade (compliance) anticorrupção </a></p>
        <p><a href="">Administrativo e Regulatório</a></p>
        <p><a href="">Startups e Fintechs </a></p>`,
        Conflict_resolution: `<p><a href="">Solução de conflitos (contencioso e arbitragem)</a></p>
        <p><a href="">Previdência complementar </a></p>
        <p><a href="">Mercado Imobiliário</a></p>
        <p><a href="">Tributário</a></p>`,
        Areas_of_expertise: "PRACTICE"
    },
    footer: {
        Contact: "Contact",
        Name: "Barros Pimentel<br /> Alcantara Gil<br /> Rodriguez <br />Advogados",
        Address: `<p>AV. Pres. Juscelino Kubitschek, 360 - Room 61<br></br> 04543-000 São Paulo, SP - Brasil </p>
        <p className="noline"><a target='_blank' href="https://www.google.com/maps/place/Av.+Pres.+Juscelino+Kubitschek,+360+-+61+-+Vila+Nova+Concei%C3%A7%C3%A3o,+S%C3%A3o+Paulo+-+SP,+13571-410/@-23.5870333,-46.6809352,17z/data=!4m5!3m4!1s0x94ce574430617541:0x39e8d53d1da721ae!8m2!3d-23.5860987!4d-46.6736082?entry=ttu" className="mapsgoogle">Ver no google maps</a></p>
        <p  className="noline"><a  target='_blank' href="https://www.waze.com/pt-BR/live-map/directions/br/sp/av.-pres.-juscelino-kubitschek,-360?navigate=yes&to=place.ChIJVVK2MVlXzpQRBDWqND6Hoh4" className="mapswaze">Ir para o Waze</a></p>`,
        Phone: "+55 11 3896.1600",
        Email: "barrospimentel@barrospimentel.adv.br",
        Linkedin: "Barros Pimentel Advogados",
        Copy: `<li><p><span className="c-primary">Barros Pimentel</span> Alcantara Gil <span className="c-primary">Rodriguez</span> Advogados</p></li>
        <li>
            <a href="#home" className="top text-uppercase c-primary" title="Back to top">Home</a>
        </li>
        <li><p className="c-primary">#BP2022</p></li>`
    },

    menu: {
        Home: `Home`,
        Who_we_are: `About Us`,
        Recognition: `Acknowledgments`,
        Performance: `Practice`,
        Professionals: `Attorneys`,
        News: `Insights`,
        Contact: `Contact`,
    },

    modal: {
        read_the_article: 'Read article',
        read_news: 'Read news',
        recognition : {
            name: "Acknowledgments",
            analysis: "Análise Advocacia",
            we_have: "We have been ranked among the most admired firms and lawyers in Brazil since 2010",
            list_1: `<h4>Practices</h4>
            <li>Civil Law</li>
            <li>Compliance</li>
            <li>Corporate Contracts</li>
            <li>Infrastructure and Regulatory</li>
            <li>Financial Transactions</li>
            <li>Corporate</li>`,
            list_2: `<h4>Industries</h4>
            <li>Sugar & Ethanol</li>
            <li>Construction & Engineering</li>
            <li>Electric Power</li>
            <li>Real Estate</li>
            <li>Technology</li>
            <li>Transport & Logistics</li>`,
            list_3: `<h3>Análise Advocacia</h3>
            <h4>Diversity and Inclusion</h4>
            <p>Firms that make a difference</p>`,
            operation_america: 'Since 2012, Chambers has recognized our firm&#39;s advice on Projects in Latin America'
    
        },
        control: {
            previous: "previous",
            home: "home",
            next: "next",
            Home: `Home`,

            Who_we_are: `about us`,
            Recognition: `acknowledgments`,
            Performance: `practice`,
            Professionals: `attorneys`,
            News: `insights`,
            Contact: `contact`,
            articles: `insights`
        },
        acting: {
            title:`Practice`,
        },
        articles: {
            title:`Articles`,    
        },
        news: {
            title:`News`,
         }
    }
};