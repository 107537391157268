import Translations from "../../../translations";
import Control from "../control";
const ActingModal = ({profesionals, setIndexProfesional, setProfessional, showModal, setShowModal, actings}) => {
    return (
        <div className={["modal", showModal == 'actings' ? 'show' : 'hide'].join(' ')}>
            <div className="content-modal  atuacaomodal atuacao-background">
                <header>
                    <div className="container">
                        <h2><Translations>modal.acting.title</Translations></h2>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="row w-100">
                            <div className="col-md-12">
                                {actings.map((acting, index) => {
                                    return (
                                        <div className="boxatuacao" key={index} id={`acting-${index}`}>
                                            <h3>{acting.name}</h3>
                                            <div dangerouslySetInnerHTML={{__html: acting.description}} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <Control showModal={showModal} close={() => {
                    setShowModal('')
                }} next={() => {
                    setShowModal('profissional')
                    setProfessional(profesionals[0]);
                    if(typeof setIndexProfesional != 'undefined'){
                        setIndexProfesional(0);
                    }
                }} before={() => {
                    setShowModal('recognition')
                }} />
            </div>
        </div>
    )
}

export default ActingModal