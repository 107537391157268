import React  from 'react';
import RecognitionModal from './recognition';
import NewsModal from './news';
import ArticlesModal from './articles';
import ActingModal from './acting';
import ProfessionModal from './profissional'


const Modals = ({profesionals, setProfessionals, profesional, setProfessional, indexProfesional, setIndexProfesional, news, articles, actings, showModal, setShowModal}) => {
    
    return(
        <>
            <RecognitionModal  showModal={showModal} setShowModal={setShowModal} />
            <ActingModal 
                actings={actings} 
                profesionals={profesionals} 
                setProfessional={setProfessional}
                indexProfesional={indexProfesional}
                showModal={showModal} 
                setShowModal={setShowModal} />

            <ProfessionModal
                showModal={showModal} 
                setShowModal={setShowModal}
                profesional={profesional}
                profesionals={profesionals}
                setProfessional={setProfessional}
                indexProfesional={indexProfesional}
                setIndexProfesional={setIndexProfesional}
            />
            <NewsModal 
                news={news} 
                showModal={showModal} 
                setShowModal={setShowModal}  
                profesional={profesional}
                profesionals={profesionals}
                setProfessional={setProfessional}
                indexProfesional={indexProfesional}
                setIndexProfesional={setIndexProfesional}    
            />
            <ArticlesModal articles={articles} showModal={showModal} setShowModal={setShowModal} /> 
        </>
    )
}

export default Modals