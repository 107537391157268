
import Translations from "../../translations"

const Footer = () => {

    return(
        <footer className="rodape">
            <a id="contato"></a>
            
            <section className="contacto">
                
                    <div className="col-md-6">
                        <h1 className="text-uppercase"><Translations>footer.Contact</Translations></h1>
                        <h3 className="text-uppercase"><Translations>footer.Name</Translations></h3>
                        
                        <div className="endereco">
                            <i className="icone"></i>
                            <div className="dados">
                                <Translations>footer.Address</Translations>
                            </div>
                        </div>
                        <p  className="noline"><a href="tel:+551138961600" className="btfone"><Translations>footer.Phone</Translations></a></p>
                        <p  className="noline"><a href="mailto:barrospimentel@barrospimentel.adv.br" className="btmail"><Translations>footer.Email</Translations></a></p>
                        <p className="noline linkedinlink">
                            <a href="https://www.linkedin.com/company/1407667/admin/" target="_blank" className="inlink">
                                <svg width="415" height="415" viewBox="0 0 415 415" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path  d="M415 415H0V0H415V415ZM55 359.73H122.52L122.5 156.63H55V359.73ZM88.3198 128.91H88.77H88.8101C112.36 128.91 127 113.31 127 93.8101C126.52 73.8799 112.31 58.72 89.21 58.72C66.1099 58.72 51 73.8799 51 93.8101C51 113.31 65.6802 128.91 88.3198 128.91ZM298.48 359.73H366V243.26C366 180.86 332.69 151.86 288.26 151.86C252.4 151.86 236.4 171.6 227.4 185.41V156.63H159.86C160.77 175.73 159.86 359.73 159.86 359.73H227.4V246.3C227.4 240.21 227.86 234.18 229.64 229.84C234.53 217.69 245.62 205.15 264.28 205.15C288.72 205.15 298.48 223.78 298.48 251.07V359.73ZM227.4 185.41C227.26 185.63 227.08 185.85 226.96 186.07H227.4V185.41Z" fill="white"/>
                                </svg>
                                <Translations>footer.Linkedin</Translations>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-6">
                        
                    </div>
                
                
            </section>
            <div className="copy">
                    <ul>
                        <Translations>footer.Copy</Translations>
                    </ul>
                </div>
        </footer>
    )
}

export default Footer