import React from "react";
import './loading.image.scss'

const LoadingImage = ({src, className, loading, setLoading}) => {
    return (
        <>
            <img src={src} style={{height: (loading ? '0px' : 'auto')}} className={className + " " + (loading ? 'hide' : 'show')} onLoad={() => { setLoading(false);  }} />
            <center className={`base-ripple`}><div className={"lds-ripple" + " " + (!loading ? 'hide' : 'show')}><div></div><div></div></div></center>
        </>
    )
}


export default LoadingImage