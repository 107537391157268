import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_PT } from "./languages/pt";
import { TRANSLATIONS_EN } from "./languages/en";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     pt: {
       translation: TRANSLATIONS_PT
     }
   }
 });


 if(i18n.language == 'pt-BR'){
  i18n.changeLanguage("pt")
 }
 if(i18n.language == 'en-US'){
  i18n.changeLanguage("pt")
 }

 console.log(i18n.language, new Date())
 



export default i18n;