import Api from "../../api/api"
import i18n from '../../translations/i18n'

class ArticlesController {
    GetAll = async (callback) => {
        callback.init()
        console.log(Api.GetLanguage())
        const response = await Api.GET(`/article/language/${Api.GetLanguage()}`)

        if(response.status === true){
            return callback.success(response)
        }
        return callback.error(response)
    }
}

export default ArticlesController