
import { Trans } from 'react-i18next'
import "./i18n";

const Translations = ({children}) => {
    return (
        <Trans components={{ 
            i: <i />, 
            strong: <strong />, 
            li: <li />, 
            h1: <h1 />, 
            h2: <h2 />, 
            h3: <h3 />, 
            h4: <h4 />, 
            h5: <h5 />, 
            h6: <h6 />, 
            span: <span />,
            a: <a />, 
            strong: <strong />}}>
            {children}
        </Trans>
    )
}

export default Translations