import Translations from "../../../translations";
import Control from "../control";

const NewsModal = ({news, showModal, setShowModal, profesional, setProfessional, profesionals, indexProfesional, setIndexProfesional}) => {
    return (
        <div className={["modal", showModal == 'news' ? 'show' : 'hide'].join(' ')}>
            <div className="content-modal noticias-background artigosmodal">
                <header>
                    <div className="container">
                        <h2><Translations>modal.news.title</Translations></h2>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="row w-100">
                            <div className="col-md-12">
                                {news.map((item, index) => {
                                    return (
                                        <div className="boxatuacao" key={index} id={`new-${index}`}>
                                            <h3> {item.name} </h3>
                                            <div dangerouslySetInnerHTML={{__html: item.description}} />
                                            {item.link != '' ? (<a target="_blank" href={item.link} className="btn read"><Translations>modal.read_news</Translations></a>) : ('')}
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <Control 
                showModal={showModal}
                before={() => {
                    setShowModal('profissional')
                    setProfessional(profesionals[profesionals.length-1]);
                    setIndexProfesional(profesionals.length-1);
                }} close={() => {
                    setShowModal('')
                }}  next={() => {
                    setShowModal('articles')
                }} />
            </div>
        </div>
    )
}

export default NewsModal