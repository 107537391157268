import React, {useEffect, useState} from "react";
import Translations from "../../translations";
import ActingsController from "./actings.controller";
import i18n from "../../translations/i18n";

const Actings = ({actings, setActings, showModal, setShowModal}) => {
    const actingsController = new ActingsController()

    const [actings1, setActings1] = useState([])
    const [actings2, setActings2] = useState([])

    useEffect(() => {
        actingsController.GetAll({
            init: () => {},
            success: (response) => {
                const half = Math.ceil(response.object.length / 2); 
                setActings(response.object)
                setActings1(response.object.slice(0, half))
                setActings2(response.object.slice(half))
            },
            error: (response) => {
                console.log('error', response)
            }
        })
    }, [])


    return (
        <div className="atuacao-conteudo">
            <div className="row">
                <div className="conteudo-1 all-actings-down"> 
                    {actings1.map((acting, index) => {
                        return <p key={index}><a onClick={() => {setShowModal('actings', `#acting-${index}`)}}>{acting.name}</a></p>
                    } )}
                </div>
            </div>
            <div className="row conteudo-2" style={i18n.language == 'en' ? {transform: 'translateX(0)'} : {}}>
                <h2> <Translations>home.Areas_of_expertise</Translations> </h2>
            </div>
            <div className="row">
                <div className="conteudo-3 all-actings-top">
                    {actings2.map((acting, index) => {
                        return <p key={index}><a onClick={() => {setShowModal('actings', `#acting-${actings1.length + index}`)}}>{acting.name}</a></p>
                    } )}
                </div>
            </div>
        </div>
    )
}

export default Actings