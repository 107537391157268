
import Translations from "../../../translations";
import Control from "../control";
const ArticlesModal = ({articles, showModal, setShowModal}) => {
    return (
        <div className={["modal", showModal == 'articles' ? 'show' : 'hide'].join(' ')}>
            <div className="content-modal artigos-background artigosmodal">
                <header>
                    <div className="container">
                        <h2><Translations>modal.articles.title</Translations></h2>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="row w-100">
                            <div className="col-md-12">
                                {articles.map((item, index) => {
                                    return(
                                        <div className="boxatuacao" key={index} id={`articles-${index}`}>
                                            <h3> {item.name} </h3>
                                            <div dangerouslySetInnerHTML={{__html: item.description.replaceAll('_blank', '')}} />
                                            {item.link != '' ? (<a href={item.link} target="_blank" className="btn read"><Translations>modal.read_the_article</Translations></a>) : ('')}
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <Control 
                showModal={showModal}
                next={() => {
                    setShowModal('who_we_are')
                    setShowModal('')
                    window.location = '#contato'
                }} close={() => {
                    setShowModal('')
                }} before={() => {
                    setShowModal('news')
                }} />
            </div>
        </div>
    )
}

export default ArticlesModal