import Api from "../../api/api"
import i18n from '../../translations/i18n'

class ActingsController {
    GetAll = async (callback) => {
        callback.init()
        const response = await Api.GET(`/actings/language/${Api.GetLanguage()}`)

        if(response.status === true){
            return callback.success(response)
        }
        return callback.error(response)
    }
}

export default ActingsController